import React, { useState } from "react";
import { Header } from "./Header";
import { AppTable } from "./AppTable";
import { FilterProps } from "../types/FilterProps";

export const AppLayout = (): JSX.Element => {
    /**
     * Declare useState for various filter parameters
     */
    const [filterParam, setFilterParam] = useState<FilterProps>({
        search: "",
        groupBy: "project.name"
    });

    return (
        <div className="maincontainer">
            <Header setFilterParam={setFilterParam} />
            <AppTable filterParam={filterParam} />
        </div>
    )
};