import React from "react";
import { GeoApps } from "@mapgear/geoapps-ui-framework";
import { AppLayout } from "./AppLayout";

export const Main = (): JSX.Element => {
      return (
        <GeoApps authorize="oidc" allowAnonymous={true} authorizationMode="silent">
            <AppLayout />
        </GeoApps >
    );
};
