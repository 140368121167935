/**
 * Redirects to a new page on a different window
 * @param url URL of the window being redirected to
 */
export const goToNewPage = (url: string) => {
    window.open(url, "_blank");
};

/**
 * Redirects to a new page on the same window
 * @param url URL of the window being redirected to
 */
export const changeCurrentPage = (url: string) => {
    window.location.replace(url);
};
