import React, { useEffect, useState } from "react";
import { useGeoApps } from "@mapgear/geoapps-ui-framework";
import { goToNewPage } from "../functions/Globalfunctions";

declare global {
	interface Window {
		_tenantUrl: boolean;
	}
};

export const CreateAppList = (): JSX.Element => {
	const [apps, setApps] = useState<any[]>([]);
	const { geoapps } = useGeoApps();
	const [loading, setLoading] = useState(true);
	const tenantUrl: string = geoapps.TenantUrl

	// Get tenant url
	const tenant = window._tenantUrl;

	// Get all app types
	useEffect(() => {
		fetch(`https://${tenant}/api/v1/apptypes`, {
			method: "GET",
		})
			.then((response) => response.json())
			.then((data) => {
				setApps(data);
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	/**
	 * Initializes create app
	 * @param id id of apps that gets created
	 */
	const clickHandler = (id: string) => {
		const body = {
			"appTypeId": id
		}

		fetch(`https://${tenant}/api/v1/apps/draft`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(body)
		})
			.then(response => response.json().then(data => ({
				status: response.status,
				body: data
			})))
			.then(obj => {
				goToNewPage(`/${obj.body.AppId}`);
			})
			.catch((error) => {
				console.error(error);
			});
	};
	
	const getContent = () => {
		if (loading) {
			return <div className="sub-menu">
				<div className="indicator">
					<div className="indicator-inner">
						<div className="loading-indicator"></div>
						<div className="indicator-title">Apps ophalen</div>
					</div>
				</div>
			</div>
		} else {
			return (
				<>
					{apps.length > 0 ? (
						<ul className="sub-menu">
							{apps.map((app, index) => (
								<li key={index} className="icon-geoapps menu-item">
									<a onClick={() => clickHandler(app.id)}>
										<span role="none" className="icon">
											<img src={app.icon} />
										</span>
										<span className="flex">
											<span className="title">{app.name}</span>
											<span className="description">{app.description}</span>
										</span>
									</a>
								</li>
							))}
						</ul>
					) :
						<ul className="sub-menu">
							<li className="icon-geoapps menu-item">
								<a href={`https://store.geoapps.cloud/?tenanturl=https://${tenantUrl}`} target="_blank">
									<span role="none" className="icon">
										<img src={"~/dist/resources/favicon.svg"} />
									</span>
									<span className="flex">
										<span className="title">GeoApps Store</span>
										<span className="description">Er zijn nog geen Apps geïnstalleerd. Ga naar de Store om deze te installeren.</span>
									</span>
								</a>
							</li>
						</ul>
					}
				</>
			)
		}
	};


	return (
		<>
			{getContent()}
		</>
	);
};
