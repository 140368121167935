import React from "react";
import { goToNewPage } from "../functions/Globalfunctions";
import { useAuth } from "react-oidc-context";
import { useGeoApps } from "@mapgear/geoapps-ui-framework";

declare global {
	interface Window {
		_createApp: boolean;
	}
}

export type StyleProps = {
	/**
	 * Whether the login window is shown or hidden
	 */
	show: boolean;
};

export const LoginOptionWindow = (props: StyleProps): JSX.Element => {
	const auth = useAuth();
	const profile = auth.user?.profile;
	const { geoapps } = useGeoApps();
	const ifAdmin = window._createApp;
	const tenantUrl: string = geoapps.TenantUrl

	// Sign out
	const signOut = () => {
		auth.signoutRedirect();
	};

	return props.show ? (
		<div className="currentUserWindow">
			<div className="top">
				<div className="user-info">
					<img className="user-image" src={profile?.picture} />
					<div className="user-details">
						<div className="user-name">{`${profile?.given_name} ${profile?.family_name}`}</div>
						<div className="user-email">{profile?.email}</div>
						<div className="user-links">
							<button onClick={() => goToNewPage(profile?.pwd_url as string)}>
								Wachtwoord wijzigen
							</button>
							<button onClick={() => goToNewPage(profile?.tenant_url as string)}>
								Mijn omgevingen
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="bottom">
				{ifAdmin && (
					<>
						<button onClick={() => goToNewPage("/manage")}>
							Beheer
						</button>
						<button onClick={() => goToNewPage(`https://store.geoapps.cloud/?tenanturl=https://${tenantUrl}`)}>
							GeoApps Store
						</button>
					</>
				)}
				<button className="logout" onClick={signOut}>
					Uitloggen
				</button>
			</div>
		</div>
	) : (
		<></>
	);
};
