import * as React from "react";
import { App } from "./App";
import { Icon } from "../icon/Icon";

export type GroupProps = {

    /**
     * React key
     */
    key?: string;

    /**
     * Index of the group (to provide a unique key)
     */
    groupIdx: number;

    /**
     * groupName is the name of the relevant group.
     */
    groupName: string;
    /**
     * appData contains data needed to build up the Apps component.
     */
    appData: any;
};

export const Group = (props: GroupProps): JSX.Element => {

    // Returns list containing app JSX elements for each group
    const appsList: JSX.Element[] = props.appData.apps.map((app, index) => {
        return <App key={`g${props.groupIdx}a${index}`} appData={app} />;
    });

    return (
        <div className="box">
            <div className="box-title">
                <h2><Icon size="18px" icon="folder" className="icon" />{props.groupName}</h2>
            </div>
            <div className="card-list">{appsList}</div>
        </div>
    );
};
