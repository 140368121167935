import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from "react";
import { FilterProps } from "../../types/FilterProps";
import { useSearchFocusBlur } from "../hooks/useSearchFocusBlur";
import { Icon } from "../icon/Icon";

export type SearchProps = {
    /**
     * setSearch useState
     */
    setSearchParam: Dispatch<SetStateAction<FilterProps>>;
};

export const Search = (props: SearchProps): JSX.Element => {
    const [inputValue, setInputValue] = useState<string>("");
    const [searchIcon, setSearchIcon] = useState<"search" | "cross">("search");
    const [buttonIcon, setButtonIcon] = useState<"search" | "cross">("search");
    const [enableMobileFocus, setEnableMobileFocus] = useState(false);
    const [value, setValue] = useState<string>("");
    const [display, setDisplay] = useState<boolean>(false);
    const search = useRef<HTMLInputElement>(null);
    const searchButton = useRef<HTMLDivElement>(null);

    const { toggleFocus, searchBlur, searchFocus } = useSearchFocusBlur(search);

    /**
     *  Create delay of 250ms for search request.
     *  There will only be a request send when the user stops typing.
     */
    useEffect(() => {
        const searchDelay = setTimeout(() => {
            props.setSearchParam((prevState) => ({ ...prevState, search: inputValue }));
        }, 250);

        return () => clearTimeout(searchDelay);
    }, [inputValue]);

    // Focus on search in mobile when search in open
    useEffect(() => {
        if (enableMobileFocus) {

            if (buttonIcon === "cross") {
                searchFocus();
            } else {
                searchBlur();
            }

        }
    }, [buttonIcon])

    /**
     * Handle click of search button in mobile
     */
    const mobileHandleClick = () => {
        if (!search.current) return;

        if (!enableMobileFocus)
            setEnableMobileFocus(true);

        if (buttonIcon == "cross") {
            setButtonIcon("search");
        } else {
            setButtonIcon("cross");
        }

        setDisplay(!display);
    };
    
    // Handle onChange of input, changes search value
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value;
        setValue(searchValue);
        handleSearchState(searchValue);
    };

    // Handle state values
    const handleSearchState = (searchValue: string) => {
        // Change icon type
        if (searchValue) {
            setSearchIcon("cross");
        } else {
            setSearchIcon("search");
        };

        // Set input value
        if (searchValue.length < 3) {
            setInputValue("");
        } else if (searchValue.length >= 3) {
            setInputValue(searchValue);
        }
    };

    // Clear input on Escape button
    const clearInput = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === "Escape") {
            if (!value) {
                searchBlur();
            }
            setValue("");
            setInputValue("");
            setSearchIcon("search");
        }
    };

    // Handle search button onClick event
    const handleClick = () => {
        setDisplay(true);
        if (searchIcon === "search") {
            toggleFocus();
        };
        if (searchIcon === "cross") {
            setValue("");
            setInputValue("");
            setSearchIcon("search");
        }
    };

    useEffect(() => {
        document.addEventListener("click", onDocumentClick);

        return () => {
            document.removeEventListener("click", onDocumentClick);
        }
    }, []);

    /**
    * Detects click outside of search
    * Hides search in mobile
    */
    const onDocumentClick = (e: MouseEvent) => {
        if (searchButton.current && !searchButton.current?.contains((e.target) as Node)) {
            setDisplay(false);
            setButtonIcon("search");
        }
    };

    return (
        <div ref={searchButton}>
            <button className="mobile-searchbutton" onMouseDown={(e) => e.preventDefault()} onClick={mobileHandleClick} title="Zoeken...">
                <Icon size="15px" icon={buttonIcon} className="icon" />
            </button>
            <div className={"search" + (display ? " show" : "")}>
                <input
                    id="search"
                    placeholder="Zoeken..."
                    className="searchbar"
                    onChange={handleChange}
                    onKeyDown={clearInput}
                    type="text"
                    ref={search}
                    value={value}
                />
                <button onMouseDown={(e) => e.preventDefault()} onClick={handleClick} title="Zoeken...">
                    <Icon size="15px" icon={searchIcon} className="icon" />
                </button>
            </div>
        </div>
    );
};
