import React, { useEffect, useState } from 'react';
import isHotkey from 'is-hotkey';

/**
 * Hook to toggle focus on the search in Search.tsx.
 */
export const useSearchFocusBlur = (search: React.RefObject<HTMLInputElement | null>) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener("keydown", onKeydown);
        search.current?.addEventListener("focus", onFocus);
        search.current?.addEventListener("blur", onBlur);

        return () => {
            window.removeEventListener("keydown", onKeydown);
            search.current?.removeEventListener("focus", onFocus);
            search.current?.removeEventListener("blur", onBlur);
        }
    }, []);

    // Focus on cmd/Ctrl+F
    // Blur on enter
    const onKeydown = (e: KeyboardEvent) => {
        if (isHotkey(["ctrl+f", "cmd+f", "/", "f3"], e)) {
            e.preventDefault();
            searchFocus();
        }
        if (isHotkey("enter", e))
            searchBlur();
    };

    /**
     * Trigger on focus
     */
    const onFocus = () => {
        setIsFocused(true);
    };

    /**
     * Trigger on blur
     */
    const onBlur = () => {
        setIsFocused(false);
    };

    const searchFocus = () => {
        if (search.current) {
            search.current.select();
            search.current.focus();
        }
    };

    // Functions to blur or focus on input
    const searchBlur = () => {
        if (search.current) {
            search.current.blur();
        }
    };

    // Function to toggle focus on search input.
    const toggleFocus = () => {
        if (!isFocused && search.current) {
            searchFocus();
        } else if (search.current) {
            searchBlur();
        }
    };

    return {
        toggleFocus,
        searchBlur,
        searchFocus
    }
};
